import React, { ComponentProps, useState } from 'react'

import { DateSelect, FormGroup, FormText } from '@te-digi/styleguide'

import { Code } from '../../components/Code'

const DateSelectNoDayExample = () => {
  const [value, setValue] = useState<
    ComponentProps<typeof DateSelect>['value'] | null
  >(null)

  return (
    <FormGroup noMargin>
      <DateSelect
        ariaLabelledby="date-select-no-day-example"
        label="Valitse kuukausi"
        maxYear={new Date().getFullYear() + 10}
        minYear={2020}
        noDay
        onChange={date => setValue(date)}
        value={value}
        descendYear
      />
      <FormText>
        Valittu: <Code>{JSON.stringify(value)}</Code>
      </FormText>
    </FormGroup>
  )
}

export { DateSelectNoDayExample }
