import React, { ComponentProps, useState } from 'react'
import { DateSelect, FormGroup, FormText } from '@te-digi/styleguide'

import { Code } from '../../components/Code'

const DateSelectExample = () => {
  const [value, setValue] =
    useState<ComponentProps<typeof DateSelect>['value']>(null)

  return (
    <FormGroup noMargin>
      <DateSelect
        help="Help"
        label="Valitse päivämäärä"
        maxYear={new Date().getFullYear() + 10}
        minYear={2020}
        onChange={date => setValue(date)}
        subLabel="Sub Label"
        required
        clearable
        value={value}
      />
      <FormText>
        Valittu: <Code>{JSON.stringify(value)}</Code>
      </FormText>
    </FormGroup>
  )
}

export { DateSelectExample }
