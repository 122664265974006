import React from 'react'

import { DateSelect, List, ListItem, Paragraph } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { DateSelectErrorExample } from '../../examples/react/DateSelectErrorExample'
import { DateSelectExample } from '../../examples/react/DateSelectExample'
import { DateSelectNoDayExample } from '../../examples/react/DateSelectNoDayExample'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="DateSelect"
    components={[{ component: DateSelect }]}
  >
    <Section>
      <List variant="unordered">
        <ListItem>
          Kuukausi-vaihtoehtojen rajaus onnistuu myös kirjoittamalla numeron.
        </ListItem>
        <ListItem>
          Komponentin palauttamassa arvossa kuukausi alkaa <Code>Date</Code>
          -tyypin mukaisesti <Code>0</Code>:sta.
        </ListItem>
      </List>
    </Section>
    <Section title="Esimerkkejä">
      <Playground
        enableOverflow
        example={DateSelectNoDayExample}
        WrapperComponent={FormLayoutWrapper}
      />
      <Playground
        enableOverflow
        example={DateSelectExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Virheviestit">
      <Paragraph>
        Virheviestin voi määritellä erikseen jokaiselle kentälle tai käyttää
        yhteistä virheviestiä.
      </Paragraph>
      <Playground
        enableOverflow
        example={DateSelectErrorExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Disabloitu">
      <Playground WrapperComponent={FormLayoutWrapper}>
        <DateSelect
          disabled
          help="Help"
          label="Valitse päivämäärä"
          subLabel="Sub Label"
        />
      </Playground>
    </Section>
  </Content>
)

export default Page
