import React, { ComponentProps, useState } from 'react'

import { DateSelect, FormGroup } from '@te-digi/styleguide'

const DateSelectErrorExample = () => {
  const [value1, setValue1] = useState<
    ComponentProps<typeof DateSelect>['value'] | null
  >(null)

  const [value2, setValue2] = useState<
    ComponentProps<typeof DateSelect>['value'] | null
  >(null)

  return (
    <>
      <FormGroup>
        <DateSelect
          ariaLabelledby="date-select-example"
          dayError="Day Error"
          label="Valitse päivämäärä"
          monthError="Month Error"
          onChange={date => setValue1(date)}
          value={value1}
          yearError="Year Error"
        />
      </FormGroup>
      <FormGroup noMargin>
        <DateSelect
          ariaLabelledby="date-select-example"
          error="Shared Error"
          label="Valitse päivämäärä"
          onChange={date => setValue2(date)}
          value={value2}
        />
      </FormGroup>
    </>
  )
}

export { DateSelectErrorExample }
